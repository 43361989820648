@import './assets/fonts.css';

body {
  margin: 0;
  font-family: 'Open Sans', Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
}

#root {
  overscroll-behavior-y: none;
}

/* scroll bar */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #657080;
  border-radius: 5px;
}

code,
kbd,
samp,
pre {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:focus {
  outline: none;
}
/**
 * needed for safari. Safari
 * adds background color on its own
 * on hls-viewer on fullscreen
 */
#hls-viewer-dark:fullscreen {
  background-color: black !important;
}

#hls-viewer-light:fullscreen {
  background-color: white !important;
}

#root {
  --hms-ui-colors-mainBg: #222427;
  --hms-ui-colors-bgPrimary: #222427;
  --hms-ui-colors-background_dim: #26272a;
  --hms-ui-fonts-sans: 'Open Sans', Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
